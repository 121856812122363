.search-page {
    padding: 2rem;
}

.search-page__subtitle {
    color: grey;
}

.search-result-list {
    padding-left: 0.5rem;
}
.search-result {
    padding-bottom: 2rem;
    margin: 1rem 0;
}
.search-result__title {
    margin: 0 0 0.5rem 0;
}
