.archive-row {
  display: flex;
  font-size: small;
  align-items: center;
  text-decoration: none;
  margin-bottom: 2em;
}
.archive-timestamp {
  width: 100%;
}
