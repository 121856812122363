.home-page {
    min-height: 1000px;
    background: linear-gradient(#F1CA5E, #FFFFFF 800px);
    padding-top: 2em;
    font-family: 'Roboto Mono', 'Courier New', Courier, monospace;
}

a.cta-button,
button.cta-button,
.cta-button {
    background: #FD964A;
    border: 1px solid #FDD18E;
    box-sizing: border-box;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    color: #ffffff;
    font-family: 'Roboto Mono', 'Courier New', Courier, monospace;
    font-weight: 500;
    font-size: 20px;
    padding: 0.75em 1em;
}

.cta-button:hover,
.cta-button:focus {
    background: #ff9e59;
    box-shadow: 0px 0px 30px #FD964A77;
}

h1 {
    font-family: 'Quando', Georgia, Cambria;
    text-align: center;
    font-weight: 400;
    font-size: 27px;
}

.center-block {
    margin: 1em auto;
    max-width: 800px;
    text-align: center;;
}

.action-row {
    margin-bottom: 2em;
}

.action-row .action-item {
    margin: 1em 1.5em;
    display: inline-block;
}

.trophy-cabinet {
    display: flex;
    justify-content: center;
    padding: 2em 0;
    margin-bottom: 2em;
    text-align: center;
    font-family: 'Roboto Mono', 'Courier New', Courier, monospace;
}

.trophy-cabinet a.col {
    color: #000;
    min-width: 100px;
    flex-grow: 0.1;
    text-decoration: none;
    transition: transform linear 0.1s;
}

.trophy-cabinet a.col:hover,
.trophy-cabinet a.col:focus {
    color: #669fda;
    transform: scale(1.025);
}

.trophy-cabinet a.col:active  {
    color: #000;
    text-decoration: none;
}

.trophy-cabinet img {
    height: auto;
    width: 100px;
    mix-blend-mode: multiply;
    display: inline-block;
    margin: 1em auto;
}

.trophy-cabinet img.raised {
    margin-top: 0.5em;
}

.promo-cascade {
    min-height: 900px;
    position: relative;
    background-image: url("../../../public/graphics/hexagon-texture.png");
    background-repeat: no-repeat;
    background-size: 900px auto;
    background-position: 100% 0%;
    margin-top: -100px;
    padding-top: 100px;
}

.promo-cascade h2 {
    font-family: 'Quando', Georgia, Cambria;
    font-size: 34px;
    font-weight: 400;
    margin-top: 0px;
    line-height: 1.2em;
}

.promo-cascade p {
    font-family: 'Roboto Mono', 'Courier New', Courier, monospace;
    font-size: 18px;
    margin-bottom: 0px;
}

.promo-cascade .highlight {
    background-color: #F2D37A;
}

.promo-cascade [class*="block-"] {
    z-index: 1;
    display: block;
    width: 40vw;
    min-width: 500px;
}

.blockwrap {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    padding-bottom: 100px;
}

.block-1 {
    margin-left: 70px;
    margin-bottom: 100px;
}

.block-1 p {
    margin-left: 70px;
}

.block-2 {
    margin: 0px 70px 100px auto;
}

.block-3 {
    margin-left: 70px;
}

.block-3 h2 {
    margin-left: 70px;
}

.bg-texture {
    z-index: 0;
    width: 80vw;
    position: absolute;
    right: 0;
}