.dropzone {
  max-width: 600px;
  min-height: 100px;
  margin: 2em auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #989898;
}

.dropzone--disabled {
  pointer-events: none;
  opacity: 0.35;
  border-style: solid;
}

.upload-page {
  max-width: 600px;
  margin: 1em auto;
}

.step-wrap {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
}

.step-block {
  flex: 1;
  margin-bottom: 2em;
  position: relative;
  color: #545454;
}

.step-block {
  flex: 1;
  margin-bottom: 2em;
  position: relative;
  color: #545454;
}

.econf {
  font-style: italic;
  font-size: small;
}

.step-block img {
  max-width: 80%;
  display: block;
}
