@font-face {
  font-family: "Roboto Mono";
  src: local("RobotoMono"), url("./fonts/RobotoMono-Regular.woff") format("woff"),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ url("./fonts/RobotoMono-Regular.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
  font-family: "Quando";
  src: local("Quando"), url("./fonts/Quando-Regular.woff") format("woff"),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ url("./fonts/Quando-Regular.ttf")
      format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

.App {
  text-align: center;
}

.main-container {
  min-height: calc(100vh - 190px);
}

.header-logo {
  height: 50px;
  padding-left: 0.25em;
  padding-right: 0.25em;
}

.header-logo-link {
  height: 50px;
}

.header-logo-link:hover {
  opacity: 0.8;
}

.App-header {
  font-family: "Roboto Mono", monospace;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;
  min-height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-left: 10px;
}

.upload-form {
  padding: 1em;
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.link {
    background: none;
    border: none;
    cursor: pointer;
}

.menu {
  display: flex;
  align-items: center;
  width: 100%;
}

.menu-item {
  margin-left: 2em;
}

.menu-item--right {
  margin-left: auto;
  margin-right: 1em;
}

.menu-link {
  cursor: pointer;
  color: #545454;
  font-size: 18px;
  text-decoration: none;
}

.menu-link:hover {
  border-bottom: 1px solid #669fda;
  color: #669fda;
}

.menu-link.active {
  font-weight: bold;
  /* color: #669fda; */
  border-bottom: 1px solid #545454;
}

.auth {
    position: relative;
    display: inline-block;
}
.menu--user-menu {
    position: absolute;
    background: white;
    padding: 0 1em 0.5em 0;
    border-radius: 5px;
    box-shadow: 1px 8px 5px rgb(0 0 0 / 10%);
    right: 0;
    top: calc(100% + 10px);
}

h2.small {
  font-size: 18px;
  margin-bottom: 0.1em;
}

h3 {
  color: #5c5c5c;
}

.help-text {
  color: #5c5c5c;
  font-size: 13px;
  margin-top: 0.1em;
}

.secondary-text {
  color: #5c5c5c;
  font-weight: 400;
}

.col {
  height: 100%;
  flex-grow: 1;
  min-width: 400px;
  margin: 1em;
}

.col-header {
  margin: 1em 0 1em;
  font-size: 18px;
  font-family: "Roboto Mono", monospace;
  font-weight: bold;
}

.default-avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #dedede;
  overflow: hidden;
}

.icon-avatar {
  width: 40px;
  height: 40px;
}

.account-card,
.list-card,
.moment-card {
  display: block;
  padding: 1em 2em;
  font-family: Helvetica, Arial;
  border: 1px solid #f1f1f1;
  border-top: none;
  margin: 0 auto;
  max-width: 500px;
}

.account-card:first-of-type {
  border-top: 1px solid rgb(204, 214, 221);
}

.moment-card h4 {
  margin-top: 0;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.button-section-help-text {
  font-family: 'Roboto Mono';
  font-size: 12px;
  border-top: 1px solid #FDF3D7;
  padding-top: 1.5em;
  text-indent: 10px;
}

.tab-row {
  border: 1px solid #f1f1f1;
  border-top: none;
}

.heading-row {
  border: 1px solid #f1f1f1;
  border-top: none;
}

.heading-row h2,
.heading-row h3,
.heading-row h4 {
  margin: 0;
  padding: 1em;
}

.text-right {
  text-align: right;
}

.text-warn {
  color: #b3481e;
}

button.tab,
.tab {
  display: inline-block;
  font-weight: bold;
  color: #657786;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 26px;
  padding: 0.5em 0.75em;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: -1px;
  border-bottom: 3px solid transparent;
}

.tab.tab-big {
  line-height: 32px;
  font-size: 16px;
  padding: 0.75em 2em;
}

button.tab.active,
.tab.active {
  border-color: #1b95e0;
  color: #1b95e0;
}

.tab:hover,
.tab:focus {
  cursor: pointer;
  color: #1b95e0;
  background: rgba(29, 161, 242, 0.1);
}

ul {
  padding-inline-start: 22px;
  line-height: 1.75em;
}

.fill-message {
  text-align: center;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #657786;
  font-size: 13px;
  flex-direction: column;
  font-weight: bold;
  padding: 2em;
}

.fill-message p {
  text-align: left;
  font-weight: normal;
}

.center-flex-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 125px);
}

/* .center-absolute-wrap > svg,
.center-absolute-wrap > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
} */

ul.paginate-list {
  list-style-type: none;
  text-align: center;
  width: 100%;
  padding-inline-start: 0px;
}

.paginate-list li {
  display: inline-block;
  cursor: pointer;
  padding: 0.5em;
  font-weight: 600;
  color: #686868;
}

.paginate-list li:hover {
  color: #1b95e0;
  background: rgba(29, 161, 242, 0.1);
  border-radius: 5px;
  border: none;
}

.paginate-list li.selected {
  color: #1b95e0;
  cursor: default;
  border-bottom: 1px solid #1b95e0;
  pointer-events: none;
}

input::placeholder {
  font-style: italic;
  color: #cdcdcd;
}

.input-wrap {
  position: relative;
}

.input--big input {
  font-family: 'Roboto Mono', 'Courier New', Courier, monospace;
  font-weight: 500;
  font-size: 19px;
  padding: 0.65em 1em;
  border: 1px solid #9a9a9a;
  border-radius: 3px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
}

.input--big input:focus {
  outline: none;
  border-color: #FD964A;
  box-shadow: 0px 0px 30px #FD964A77;
}

.input--with-btn__wrap input {
  padding-right: 80px;
}

.input--big button {

}

.input--with-btn__wrap.input--big button {
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 19px;
  padding: .45em .8em;
  box-shadow: none;
  cursor: pointer;
}

.input--with-btn__wrap.input--big button:hover,
.input--with-btn__wrap.input--big button:focus {
  box-shadow: none;
}

.checkbox-group {
    display: block;
    margin-bottom: 1em;
}
.checkbox-group--label {
    margin-left: 1em;
}

.divider--white {
  max-width: 1000px;
  width: 75%;
  margin: 1em auto;
  background: #ffffff;
  height: 3px;
}
