.policy-container {
    display: block;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1em;
}

.emphasis {
    display: inline;
    font-weight: 600;
}
