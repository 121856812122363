/*----- GDPR Modal -------*/
.gdpr-modal-background-deny {
  position: fixed;
  display: block;
  bototm: 0;
  right: 0;
  /* width: 100%;
  height: 100%; */
  z-index: 999999;
  /* background: rgba(49, 49, 49, 0.7); */
  /* backdrop-filter: blur(10px); */
}

.gdpr-body {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  bottom: 45px;
  right: 45px;
  max-height: 92%;
  padding: 0px 16px;
  text-align: center;
  font-size: 14px;
  position: fixed;
  max-width: 40%;
  font-weight: 400;
  color: rgba(69, 79, 95);
  background-color: rgba(239, 240, 243);
  font-family: "Roboto Mono", "Courier New", Courier, monospace;
  border-radius: 3px;
  box-shadow: 0px 10px 15px rgba(10,10,10,0.4);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: rgba(20, 20, 20, 0.9);
    color: #ffffff; */
}

.group-action-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.group-action-btns .btn {
  cursor: pointer;
  margin: 10px;
  padding: 10px;
  width: 200px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Roboto Mono", "Courier New", Courier, monospace;
  line-height: 16px;
  border-radius: 4px;
  border: 1px solid #f0d18e;
}


.group-action-btns .btn:hover {
  align-items: center;
}

.description {
  line-height: 24px;
}
