:root {
  /* Colors */
  --tw-color-blue-primary: #1da1f2;
  --tw-color-text-primary: #14171a;
  --tw-color-text-link: #1b95e0;
  --tw-color-gray-deep: #657786;
  --tw-color-gray-medium: #aab8c2;
}

.left-col {
  height: 100%;
  width: 400px;
  color: #14171a;
  padding-right: 2em;
}

.feed-col {
  flex-grow: 1;
  min-width: 500px;
  margin-top: -1em;
  max-width: 60vw;
}

.metadata-col {
  height: 100%;
  width: 300px;
}

.archive-pending-label {
  position: fixed;
  bottom: 15px;
  right: 15px;
  padding: 20px;
  background: #fffdd0;
  color: #e7b90e;
  border: 1px solid #e7b90e;
  border-radius: 10px;
  box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.archive-pending-action {
  display: flex;
  flex-direction: column;
}

.archive-pending-error {
  color: red;
  margin-top: 3px;
  font-size: smaller;
}

.archive-view-mode {
  position: relative;
  font-family: "Quando";
  font-size: 21px;
  margin: 1em 0 0.75em;
  color: #545454;
}

.archive-view-mode .sticker {
  display: inline-block;
  height: 1.5em;
  width: 1.5em;
  vertical-align: bottom;
  margin-right: 0.5em;
  margin-bottom: -2px;
}

.account-info .row {
  border: 1px solid #f1f1f1;
  border-top: none;
  padding: 1em;
  font-size: 13px;
  word-wrap: break-word;
}

.account-info h3 {
  margin-top: 0px;
  margin-bottom: 0.5em;
}

.account-home,
.account-info {
  position: relative;
}

.archive-nav-card {
  border: 1.5px solid #FDF3D7;
  border-radius: 10px;
  padding: 1em;
}

.avatar-preview {
  width: 75px;
  height: auto;
  margin: 0.5em;
}