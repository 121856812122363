body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1em;
}

.btn-row {
  float: right;
  text-align: right;
}

button.btn,
.btn {
  display:inline-block;
  background: rgba(255, 255, 255);
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  text-transform: none;
  border: none;
  text-decoration: none;
  color: #14171a;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 20px;
  padding: 0.5em 0.75em;
  border-radius: 3px;
  cursor: pointer;
  -webkit-appearance: none;
}

.btn.btn-toggle {
  font-weight: 600;
  color: #657786;
  margin: 2px;
}

.btn-toggle:hover,
.btn-toggle:active {
  color: #1b95e0;
}

.btn.primary {
  background-color: #fd964a;
  color: white;
}

.btn.primary:hover {
  background-color: #ff9e59;
}

.btn.secondary {
  color: rgb(203, 133, 84);
}

.btn.secondary:hover {
  color: rgb(255, 255, 255);
  background: rgb(203, 133, 84);
}

.btn.btn-small {
  font-size: 0.9rem;
  line-height: 1.25em;
  margin: 2px;
  padding: 0.45em 0.5em;
}

.input--with-btn__wrap {
  position: relative;
}

.input--with-btn__wrap .btn {
  position: absolute;
  right: 1px;
  top: calc(4px + .25em);
  height: calc(2em);
  line-height: 1em;
}

button.rounded-btn,
.rounded-btn {
  display: inline-block;
  font-weight: bold;
  border-radius: 1.5em;
  font-size: 14px;
  cursor: pointer;
}

.rounded-btn.secondary {
  background: rgba(255, 255, 255);
}

.rounded-btn:hover,
.rounded-btn.active {
  color: #1b95e0;
}

.rounded-btn:hover {
  background: rgba(29, 161, 242, 0.1);
}

.btn-stack {
  margin: 0.5em 0;
  overflow: visible;
}

.btn-stack .btn {
  margin-bottom: 0.25em;
}

input, .input {
    padding: 0.65em 1em;
    border: 1px solid #9a9a9a;
    border-radius: 3px;
}

input:focus, .input:focus {
  outline: none;
  border-color: #FD964A;
  box-shadow: 0px 0px 5px #FD964A60;
}

.dropzone {
  text-align: center;
  padding: 30px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  cursor: pointer;
  margin-bottom: 20px;
}

